<template>
  <div>
    <div class="container pt-4">
      <div class="row pt-3 px-3">
        <template>
          <div
            id="order"
            v-for="order in orderStatusDelivered"
            class="col-sm-12 col-md-6 col-xl-4 history-card mt-2 px-0"
            :key="order.id"
          >
            <div class="row history-card__container p-4 mx-3">
              <div class="col col-8 text-left">
                <div class="history-card--Heading">
                  {{ order.name }}
                </div>
                <div class="history-card--Order">
                  <div>Order No. {{ order.id }}</div>
                  <div>{{ humanize(order.date) }}</div>
                </div>
              </div>
              <div class="col col-4 text-center">
                <div class="history-card--Heading">
                  <span v-if="region === 'US'">$ </span
                  ><span v-else>PKR </span> {{ order.amount }}
                </div>
                <div class="history-card--Status">{{ order.status }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}order-history`,
  data() {
    return {
      perPage: 20,
      rows: 10
    };
  },
  computed: {
    ...mapState({
      region: state => {
        if (state.regions.region === "") {
          return "";
        }
        return state.regions.region ? "US" : "PK";
      },
      orderStatusDelivered: ({ regions, orders: storeOrders = {} }) => {
        const { orderStatusDelivered: orders = [] } = storeOrders;
        // const isUs = regions.region;
        if (!regions.region) {
          return orders.map(order => ({
            // eslint-disable-next-line no-underscore-dangle
            customerName: order.receiver.name,
            status: order.types.status,

            id: order.orderId,
            amount: order.amount.total,
            date: order.time.delivered
          }));
        }
        return orders.map(order => ({
          // eslint-disable-next-line no-underscore-dangle
          customerName: order.customer.name,
          status: order.status,

          id: order.orderId,
          amount: order.orderAmount,
          date: order.orderTimeDate
        }));
      }
    })
  },
  methods: {
    humanize(date) {
      const humanDate = moment(date).format("MMMM Do YYYY");
      return humanDate;
    }
  }
};
</script>

<style lang="scss" scoped></style>
